<template>
  <div class="miruone-view">
    <header class="fv-main">
      <picture>
        <source
          srcset="../../assets/images/views/enterprise-view/miruone/fv-bg.webp"
          media="(min-width:600px)"
          type="image/webp"
        />
        <source
          srcset="
            ../../assets/images/views/enterprise-view/miruone/fv-bg-600.webp
          "
          type="image/webp"
        />
        <img
          decoding="async"
          loading="eager"
          class="fv-bg"
          :src="
            require('@/assets/images/views/enterprise-view/miruone/fv-bg.jpg')
          "
          width="957"
          height="395"
          alt="fv bg"
        />
      </picture>
      <h1>
        <picture>
          <source
            srcset="
              ../../assets/images/views/enterprise-view/miruone/fv-text.webp
            "
            type="image/webp"
          />
          <img
            decoding="async"
            loading="eager"
            class="fv-text"
            :src="
              require('@/assets/images/views/enterprise-view/miruone/fv-text.png')
            "
            width="2446"
            height="1100"
            alt="河川防災監視の決定版！ みるわん"
          />
        </picture>
      </h1>
      <img
        decoding="async"
        loading="eager"
        class="fv-logo"
        :src="
          require('@/assets/images/views/enterprise-view/miruone/logo-company.svg')
        "
        width="469"
        height="42"
        alt="logo company"
      />
    </header>
    <main>
      <section class="sec01">
        <h2 class="sec01-title">
          こんな<span class="big">お悩み</span>はありませんか？
        </h2>
        <div class="sec01-flex">
          <div class="flex-item item1">
            <div>大雨時の</div>
            <div><span class="r-cyan">河川巡視</span>が大変</div>
          </div>
          <div class="flex-item item2">
            <div class="r-cyan">夜間は</div>
            <div><span class="r-cyan">監視カメラ</span>で</div>
            <div>状況が把握できない</div>
          </div>
          <div class="flex-item item3">
            <div>水防に必要な</div>
            <div class="r-cyan">情報がバラバラ</div>
          </div>
        </div>
      </section>

      <section class="sec01-arrow">
        <div class="arrow1"></div>
        <div class="arrow2"></div>
        <div class="arrow3"></div>
      </section>

      <section class="sec02">
        <div class="text1">
          （株）建設技術研究所のIoT監視観測パッケージ<br class="sp" /><span
            class="big"
            >「みるわん」</span
          >なら、
        </div>
        <div class="text2">高感度監視カメラや水位観測で、</div>
        <div class="text2">
          <span class="big bold r-cyan">現地の状況変化とその勢い</span
          ><br class="sp" />までを確実に捉えることができます。
        </div>
        <div class="text3">
          雨量計は5分毎、水位計と監視カメラは1分毎に<br
            class="sp"
          />データ配信が可能です。
        </div>
        <a
          class="cta-btn"
          href="https://www.cti-rains.net/mailform/form_miruone.html"
          target="_blank"
        >
          <picture>
            <source
              srcset="
                ../../assets/images/views/enterprise-view/miruone/cta-btn.webp
              "
              type="image/webp"
            />
            <img
              decoding="async"
              loading="lazy"
              :src="
                require('@/assets/images/views/enterprise-view/miruone/cta-btn.png')
              "
              width="800"
              height="210"
              alt="cta"
            />
          </picture>
          <div class="cta-text1">パンフレットの</div>
          <div class="cta-text2">ダウンロードはこちら！</div>
        </a>
      </section>

      <section class="sec03">
        <div class="title-bg">BENEFIT</div>
        <div class="title">
          <div class="title1">BENEFIT</div>
          <div class="title2">「みるわん」導入の</div>
          <div class="title3">
            <span class="r-cyan"><span class="big">5</span>つ</span>の<span
              class="big"
              >メリット</span
            >
          </div>
        </div>

        <div class="merit-flex3">
          <div class="merit-item" data-bg="01">
            <div class="merit-head">高感度監視カメラ</div>
            <div class="merit-desc">
              <div>星あかりでもカラー撮影が可能な</div>
              <div>高感度な監視カメラ</div>
            </div>
          </div>
          <div class="merit-item" data-bg="02">
            <div class="merit-head">プライバシー配慮</div>
            <div class="merit-desc">
              <div>
                AIマスキング搭載で<br class="pc" />プライバシーにも配慮した
              </div>
              <div>カメラ映像を一般公開</div>
            </div>
          </div>
          <div class="merit-item" data-bg="03">
            <div class="merit-head">ワンパッケージ導入</div>
            <div class="merit-desc">
              <div>水位計・雨量計・冠水センサーも</div>
              <div>ワンパッケージの導入で</div>
              <div>確実に現地状況を把握</div>
            </div>
          </div>
        </div>
        <div class="merit-flex2">
          <div class="merit-item" data-bg="04">
            <div class="merit-head">
              <span><span data-ruby="リスクマ">RisKma</span>と連携</span>
            </div>
            <div class="merit-desc">
              <div>
                「<a :href="`${currentDomain}/pdf/RisKma.pdf`" target="_blank"
                  >RisKma（リスクマ）</a
                >」と連携することで、
              </div>
              <div>
                予測雨量などの気象情報、<br
                  class="sp"
                />既設観測所の雨量、水位、
              </div>
              <div>カメラ情報と同時に閲覧が可能</div>
            </div>
          </div>
          <div class="merit-item" data-bg="05">
            <div class="merit-head">
              <span>
                <span class="small">専門的なデータやノウハウを基に</span><br />
                ご提案・サポート
              </span>
            </div>
            <div class="merit-desc">
              <div>河川コンサルタントが蓄積した</div>
              <div>専門的なデータやノウハウを基に、</div>
              <div>適切な設置個所など、ご提案・サポートします。</div>
            </div>
          </div>
        </div>
      </section>

      <section class="sec04">
        <div class="title-bg">CASE STUDY</div>
        <div class="title">
          <div class="title1">CASE STUDY</div>
          <div class="title2">導入実績</div>
        </div>

        <div class="study-box">
          <div class="study-info">
            <div>
              全情報を1画面に集約<br />
              旧システムからRisKmaへ <br />
              全面的な切り替えを目指す
            </div>
            <div class="study-memo">静岡県 袋井市 様</div>
          </div>
          <div class="study-photo">
            <picture>
              <source
                srcset="
                  ../../assets/images/views/enterprise-view/miruone/photo-book-fukuroi.webp
                "
                type="image/webp"
              />
              <img
                decoding="async"
                loading="lazy"
                :src="
                  require('@/assets/images/views/enterprise-view/miruone/photo-book-fukuroi.png')
                "
                width="417"
                height="352"
                alt="book"
              />
            </picture>
          </div>
        </div>
        <div class="study-links">
          <div class="study-look">詳しくみる</div>
          <a
            class="study-link"
            :href="`${currentDomain}/pdf/RisKma-袋井市.pdf`"
            target="_blank"
          >
            (PDF 3.1MB)
            <img
              decoding="async"
              loading="lazy"
              :src="
                require('@/assets/images/views/enterprise-view/miruone/icon-download-cyan.svg')
              "
              width="38"
              height="38"
              alt="download"
            />
          </a>
        </div>

        <div class="study-box">
          <div class="study-info">
            <div>
              監視カメラと水害リスク管理システムの<br />
              双方を一元化した唯一の提案を評価<br />
              コンサルティングを、今後の施策に活かす
            </div>
            <div class="study-memo">八王子市役所 水環境整備課 様</div>
          </div>
          <div class="study-photo">
            <picture>
              <source
                srcset="
                  ../../assets/images/views/enterprise-view/miruone/photo-book-hachioji.webp
                "
                type="image/webp"
              />
              <img
                decoding="async"
                loading="lazy"
                :src="
                  require('@/assets/images/views/enterprise-view/miruone/photo-book-hachioji.png')
                "
                width="417"
                height="352"
                alt="book"
              />
            </picture>
          </div>
        </div>
        <div class="study-links">
          <div class="study-look">詳しくみる</div>
          <a
            class="study-link"
            :href="`${currentDomain}/pdf/RisKma-八王子市.pdf`"
            target="_blank"
          >
            (PDF 1.41MB)
            <img
              decoding="async"
              loading="lazy"
              :src="
                require('@/assets/images/views/enterprise-view/miruone/icon-download-cyan.svg')
              "
              width="38"
              height="38"
              alt="download"
            />
          </a>
        </div>

        <div class="study-box">
          <div class="study-info">
            <div>
              工業団地での実証事業を通じて、<br />
              「災害レジリエンスNo.1」の実現を目指す<br />
              群馬県の取組に貢献
            </div>
            <div class="study-memo">群馬県未来投資・デジタル産業課 様</div>
          </div>
          <div class="study-photo">
            <picture>
              <source
                srcset="
                  ../../assets/images/views/enterprise-view/miruone/photo-book-gunma.webp
                "
                type="image/webp"
              />
              <img
                decoding="async"
                loading="lazy"
                :src="
                  require('@/assets/images/views/enterprise-view/miruone/photo-book-gunma.png')
                "
                width="417"
                height="352"
                alt="book"
              />
            </picture>
          </div>
        </div>
        <div class="study-links">
          <div class="study-look">詳しくみる</div>
          <a
            class="study-link"
            :href="`${currentDomain}/pdf/RisKma-群馬県.pdf`"
            target="_blank"
          >
            (PDF 1.55MB)
            <img
              decoding="async"
              loading="lazy"
              :src="
                require('@/assets/images/views/enterprise-view/miruone/icon-download-cyan.svg')
              "
              width="38"
              height="38"
              alt="download"
            />
          </a>
        </div>

        <div class="study-box">
          <div class="study-info">
            <div>
              苫小牧市と共同で開発・導入した<br />
              大雨管理システムが<br />
              国土交通大臣賞（循環のみち下水道賞）を受賞
            </div>
            <div class="study-memo">苫小牧市役所 様</div>
          </div>
          <div class="study-photo">
            <picture>
              <source
                srcset="
                  ../../assets/images/views/enterprise-view/miruone/photo-book-tomakomai.webp
                "
                type="image/webp"
              />
              <img
                decoding="async"
                loading="lazy"
                :src="
                  require('@/assets/images/views/enterprise-view/miruone/photo-book-tomakomai.png')
                "
                width="417"
                height="352"
                alt="book"
              />
            </picture>
          </div>
        </div>
        <div class="study-links">
          <div class="study-look">詳しくみる</div>
          <a
            class="study-link"
            :href="`${currentDomain}/pdf/RisKma-苫小牧市.pdf`"
            target="_blank"
          >
            (PDF 985KB)
            <img
              decoding="async"
              loading="lazy"
              :src="
                require('@/assets/images/views/enterprise-view/miruone/icon-download-cyan.svg')
              "
              width="38"
              height="38"
              alt="download"
            />
          </a>
        </div>
      </section>

      <section class="sec05">
        <div class="title-bg">CONTACT</div>
        <div class="title">
          <div class="title1">CONTACT</div>
          <div class="title2">お問い合わせ・お見積り</div>
        </div>

        <v-card flat color="transparent">
          <v-card
            class="pa-4 mx-auto"
            style="margin-top: 10vh"
            outline
            tile
            width="600"
          >
            <div class="d-flex">
              <v-card>
                <v-img
                  width="110"
                  height="170"
                  :src="
                    require('@/assets/images/views/enterprise-view/miruone/IE3Y14305.png')
                  "
                ></v-img>
              </v-card>
              <v-card flat class="px-4 py-2">
                <h3>○ みるわん標準パッケージ</h3>
                <div class="caption mt-3">型番</div>
                <div>IE3Y14305</div>
                <div class="caption mt-3">仕様</div>
                <div>カメラ・雨量計・水位計・バッテリーパッケージ</div>
              </v-card>
            </div>
            <v-divider class="my-3" />
            <div class="d-flex">
              <v-card>
                <v-img
                  width="110"
                  height="170"
                  :src="
                    require('@/assets/images/views/enterprise-view/miruone/IE3Y14855.png')
                  "
                ></v-img>
              </v-card>
              <v-card flat class="px-4 py-2">
                <h3>○ みるわんカメラパッケージ</h3>
                <div class="caption mt-3">型番</div>
                <div>IE3Y14855</div>
                <div class="caption mt-3">仕様</div>
                <div>カメラ・小型バッテリーパッケージ</div>
              </v-card>
            </div>
          </v-card>
        </v-card>

        <a
          class="contact-btn"
          href="https://www.cti-rains.net/mailform/form_miruone.html"
          target="_blank"
        >
          <img
            decoding="async"
            loading="lazy"
            class="contact-icon"
            :src="
              require('@/assets/images/views/enterprise-view/miruone/icon-mail.svg')
            "
            width="105"
            height="61"
            alt="mail"
          />
          <div class="contact-info">
            <div style="font-size: 50%">
              <span class="pc">（株）建設技術研究所の</span
              >IoT監視観測パッケージ「みるわん」
            </div>
            <div>
              <span style="color: #f5fc4f">お問い合わせ・お見積り</span>はこちら
            </div>
          </div>
        </a>
      </section>
    </main>

    <footer>
      <img
        decoding="async"
        loading="lazy"
        class="footer-logo-miruone"
        :src="
          require('@/assets/images/views/enterprise-view/miruone/logo-miruone.svg')
        "
        width="440"
        height="136"
        alt="logo miruone"
      />
      <v-row no-gutters align="center" justify="center" class="footer-logos">
        <a
          href="https://www.ctie.co.jp/index.html"
          target="_blank"
          class="mr-6"
        >
          <img
            class="footer-logo-company"
            decoding="async"
            loading="lazy"
            :src="
              require('@/assets/images/views/enterprise-view/miruone/logo-company.svg')
            "
            alt="logo company"
          />
        </a>
        <a href="https://riskma.net" target="_blank">
          <img
            class="footer-logo-riskma"
            decoding="async"
            loading="lazy"
            :src="
              require('@/assets/images/views/enterprise-view/miruone/riskma-logo.png')
            "
            alt="logo riskma"
          />
        </a>
      </v-row>

      <div class="footer-links">
        担当部署：東京本社水システム部 流域情報サービス室
      </div>
      <div class="copyright">
        Copyright RisKma ・ All Rights Reserved.
        「RisKma」は株式会社建設技術研究所の登録商標です．
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "MiruoneView",
  data() {
    return {
      currentDomain: window.location.origin,
    };
  },
};
</script>
<style>
:root {
  --color-theme: #20a7e6;
  --fg-color: #222;
  --bg-light: #20a7e60f;
  --font-gothic: "Inter", "Noto Sans JP", "Helvetica Neue", "Helvetica",
    "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo",
    sans-serif;
}
</style>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  display: block;
  width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  text-decoration: none;
  transition: 0.5s;
}

@media (hover: hover) {
  a:hover {
    opacity: 0.5;
  }
}

.bold {
  font-weight: 700;
}

.big {
  font-size: 120%;
}

.small {
  font-size: 80%;
}

.r-cyan {
  color: var(--color-theme);
}

.pc {
  display: none;
}
.sp {
  display: inline;
}

@media (min-width: 800px) {
  .pc {
    display: inline;
  }
  .sp {
    display: none;
  }
}

html {
  font-size: clamp(16px, 1vw, 20px);
}

body {
  font-family: var(--font-gothic);
  animation: anim-fadeIn 1s ease forwards;
}

@keyframes anim-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

header {
  position: relative;
  width: 100%;
}

header .fv-bg {
  width: 100%;
}

header .fv-text {
  position: absolute;
  top: 1vw;
  left: 15%;
  width: 70%;
}

header .fv-logo {
  position: absolute;
  top: 1vw;
  left: 1vw;
  width: 15vw;
}

main {
  width: 100%;
  background: #fff;
}
main .sec01 {
  margin: 0 auto;
  padding: clamp(20px, 3vw, 40px) 2%;
  width: 100%;
  max-width: 1200px;
}
main .sec01 h2.sec01-title {
  margin: 0;
  width: 100%;
  font-size: 6vw;
  font-weight: 700;
  text-align: center;
}
main .sec01 .sec01-flex {
  margin: clamp(20px, 3vw, 40px) auto;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  gap: 4vw;
}
main .sec01 .sec01-flex .flex-item {
  position: relative;
  margin: 0;
  padding: 0;
  width: 80%;
  aspect-ratio: 360 / 100;
  border: 4px solid #20a7e633;
  background: transparent;
  border-radius: 200px;
  color: var(--fg-color);
  font-size: 4.6vw;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
main .sec01 .sec01-flex .flex-item.item1::before {
  position: absolute;
  content: "?";
  top: 5%;
  left: 5%;
  color: #20a7e633;
  font-size: clamp(66px, 11vw, 132px);
  font-weight: 700;
  transform: rotate(-20deg);
  z-index: 0;
}
main .sec01 .sec01-flex .flex-item.item2::before {
  position: absolute;
  content: "?";
  top: 2%;
  right: 8%;
  color: #20a7e633;
  font-size: clamp(66px, 11vw, 132px);
  font-weight: 700;
  transform: rotate(10deg);
  z-index: 0;
}
main .sec01 .sec01-flex .flex-item.item3::before {
  position: absolute;
  content: "?";
  top: 2%;
  left: 4%;
  color: #20a7e633;
  font-size: clamp(66px, 11vw, 132px);
  font-weight: 700;
  transform: rotate(-20deg);
  z-index: 0;
}
main .sec01 .sec01-flex .flex-item.item1::after {
  position: absolute;
  content: "";
  top: 50%;
  right: -5%;
  width: 20%;
  aspect-ratio: 96 / 91;
  background-image: url(../../assets/images/views/enterprise-view/miruone/icon-person1.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
main .sec01 .sec01-flex .flex-item.item2::after {
  position: absolute;
  content: "";
  top: 50%;
  right: -5%;
  width: 20%;
  aspect-ratio: 96 / 91;
  background-image: url(../../assets/images/views/enterprise-view/miruone/icon-person2.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
main .sec01 .sec01-flex .flex-item.item3::after {
  position: absolute;
  content: "";
  top: 50%;
  right: -5%;
  width: 20%;
  aspect-ratio: 96 / 91;
  background-image: url(../../assets/images/views/enterprise-view/miruone/icon-person3.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

main .sec01-arrow {
  position: relative;
  margin: calc(clamp(20px, 3vw, 40px) * -1.2) 0 0;
  padding: 0;
  width: 100%;
  aspect-ratio: 1920 / 200;
  background: transparent;
}
main .sec01-arrow .arrow1 {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: var(--bg-light);
  clip-path: polygon(0% 0%, 50% 50%, 100% 0%, 100% 100%, 0% 100%);
}
main .sec01-arrow .arrow2 {
  position: absolute;
  top: 20%;
  left: calc(50% - 11vw * 0.5);
  width: 11vw;
  height: 3.8vw;
  background: #f9974666;
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
}
main .sec01-arrow .arrow3 {
  position: absolute;
  top: 40%;
  left: calc(50% - 11vw * 0.5);
  width: 11vw;
  height: 3.8vw;
  background: #f9974666;
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
}

main .sec02 {
  position: relative;
  margin: 0;
  padding: 0 0 16vw;
  width: 100%;
  background: var(--bg-light);
}
main .sec02 .text1 {
  margin-bottom: 1vw;
  font-size: 2.4vw;
  font-weight: 700;
  text-align: center;
}
main .sec02 .text2 {
  font-size: 5.4vw;
  font-weight: 700;
  text-align: center;
}
main .sec02 .text3 {
  margin-top: 2vw;
  font-size: 3.2vw;
  font-weight: 400;
  text-align: center;
}
main .sec02 .cta-btn {
  position: absolute;
  display: block;
  top: 100%;
  left: 50%;
  width: 90%;
  max-width: 800px;
  transform: translate(-50%, -70%);
  z-index: 1;
}
main .sec02 .cta-btn > img {
  width: 100%;
}
main .sec02 .cta-btn .cta-text1 {
  position: absolute;
  top: 45%;
  left: 35%;
  width: 60%;
  color: #fff;
  font-size: min(3.2vw, 36px);
  font-weight: 700;
  text-align: center;
}
main .sec02 .cta-btn .cta-text2 {
  position: absolute;
  top: 70%;
  left: 35%;
  width: 60%;
  color: #fff;
  font-size: min(2.4vw, 28px);
  font-weight: 700;
  text-align: center;
}
main .sec02 .cta-btn .cta-icon {
  position: absolute;
  top: 55%;
  right: 15%;
  width: 5%;
  max-width: 38px;
  transform: translateX(50%);
}
main .sec02 .cta-btn .cta-icon > img {
  width: 100%;
}
main .sec02 .cta-btn .cta-size {
  position: absolute;
  top: 75%;
  right: 15%;
  width: max-content;
  color: #fff;
  font-size: min(1vw, 16px);
  font-weight: 700;
  text-align: center;
  transform: translateX(50%);
}

main .sec03 {
  position: relative;
  margin-top: 10px;
  padding: 120px 2%;
  width: 100%;
  background: #fff;
}
main .sec03 .title-bg {
  width: 100%;
  color: #fafafa;
  font-size: min(16vw, 200px);
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 2vw;
  line-height: 1;
}
main .sec03 .title {
  margin-top: -160px;
  width: 100%;
}
main .sec03 .title .title1 {
  margin: min(1vw, 12px) 0;
  width: 100%;
  color: var(--color-theme);
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2px;
  line-height: 1;
}
main .sec03 .title .title2 {
  width: 100%;
  color: var(--fg-color);
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  line-height: 1.5;
}
main .sec03 .title .title3 {
  width: 100%;
  color: var(--fg-color);
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  line-height: 1;
}
main .sec03 .r-info {
  margin: 2rem 0;
  color: var(--fg-color);
  font-size: min(1.5vw, 18px);
  font-weight: 400;
  text-align: center;
}
main .sec03 .merit-flex3 {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
}
main .sec03 .merit-flex3 .merit-item {
  position: relative;
  margin: 2vw 0;
  padding: 2%;
  width: 100%;
  border: 3px solid var(--color-theme);
  border-radius: 10px;
}
main .sec03 .merit-flex2 {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
}
main .sec03 .merit-flex2 .merit-item {
  position: relative;
  margin: 2vw 0;
  padding: 2%;
  width: 100%;
  border: 3px solid var(--color-theme);
  background: #fff;
  border-radius: 10px;
}
main .sec03 .merit-flex3 .merit-item::before,
main .sec03 .merit-flex2 .merit-item::before {
  position: absolute;
  content: attr(data-bg);
  top: 6%;
  left: 0;
  width: 100%;
  color: #f4f4f4;
  font-size: 20vw;
  font-weight: 700;
  text-align: center;
  line-height: 1;
}
main .sec03 .merit-flex3 .merit-item .merit-head,
main .sec03 .merit-flex2 .merit-item .merit-head {
  position: relative;
  width: 100%;
  color: var(--fg-color);
  font-size: 6vw;
  font-weight: 700;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 3px solid #20a7e64c;
}
main .sec03 .merit-flex3 .merit-item .merit-head,
main .sec03 .merit-flex2 .merit-item .merit-head {
  height: 20vw;
}
main .sec03 .merit-flex3 .merit-item .merit-desc,
main .sec03 .merit-flex2 .merit-item .merit-desc {
  padding: 1rem 0 0;
  width: 100%;
  color: var(--fg-color);
  font-size: 4vw;
  font-weight: 400;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
main .sec03 .merit-flex3 .merit-item .merit-desc,
main .sec03 .merit-flex2 .merit-item .merit-desc {
  height: 26vw;
}
main .sec03 .merit-item span[data-ruby] {
  position: relative;
}
main .sec03 .merit-item span[data-ruby]::before {
  position: absolute;
  content: attr(data-ruby);
  display: inline-block;
  left: 50%;
  bottom: 100%;
  font-size: 50%;
  width: max-content;
  transform: translateX(-50%);
}
main .sec03 .merit-item a {
  display: inline;
  color: var(--color-theme);
  text-decoration: underline;
}

main .sec04 {
  position: relative;
  margin: 0;
  padding: 80px 2%;
  width: 100%;
  background: var(--bg-light);
}
main .sec04 .title-bg {
  width: 100%;
  color: #fff;
  font-size: min(11.1vw, 180px);
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 2vw;
  line-height: 1;
}
main .sec04 .title {
  margin-top: calc(-1 * min(11.1vw, 180px));
  width: 100%;
}
main .sec04 .title .title1 {
  margin: min(1vw, 12px) 0;
  width: 100%;
  color: var(--color-theme);
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2px;
  line-height: 1;
}
main .sec04 .title .title2 {
  width: 100%;
  color: var(--fg-color);
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  line-height: 1.5;
}
main .sec04 .study-box {
  margin: 6vw auto 0;
  padding: 4% 4%;
  width: 100%;
  max-width: 1200px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
}
main .sec04 .study-box .study-info {
  padding: 0;
  width: 70%;
  color: var(--fg-color);
  font-size: 2.8vw;
  font-weight: 700;
  line-height: 1.8;
}
main .sec04 .study-box .study-memo {
  margin-top: 1rem;
  color: var(--color-theme);
  font-size: 2vw;
}
main .sec04 .study-box .study-photo {
  margin-top: -0%;
  width: 25%;
  transform-origin: right bottom;
  transform: scale(1.3, 1.3);
}
main .sec04 .study-box .study-photo img {
  width: 100%;
}
main .sec04 .study-links {
  margin: 0 auto 4vw;
  padding: 1rem;
  width: 100%;
  max-width: 1200px;
  background: #e0f5ff;
  display: flex;
  align-items: normal;
  justify-content: end;
}
main .sec04 .study-links .study-look {
  width: 60%;
  text-align: center;
  color: var(--color-theme);
  font-size: 2.8vw;
  font-weight: 700;
  white-space: nowrap;
}
main .sec04 .study-links .study-link {
  width: 20%;
  color: var(--color-theme);
  font-size: 2vw;
  font-weight: 700;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
main .sec04 .study-links .study-link img {
  width: 22px;
  height: 22px;
}

main .sec05 {
  position: relative;
  margin: 0;
  padding: 80px 2% 8%;
  width: 100%;
  background: #20a7e624;
}
main .sec05 .title-bg {
  width: 100%;
  color: #ffffffb3;
  font-size: min(16.7vw, 200px);
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 2vw;
  line-height: 1;
}
main .sec05 .title {
  margin-top: calc(-1 * min(16.7vw, 200px));
  width: 100%;
}
main .sec05 .title .title1 {
  margin: min(1vw, 12px) 0;
  width: 100%;
  color: var(--color-theme);
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2px;
  line-height: 1;
}
main .sec05 .title .title2 {
  width: 100%;
  color: var(--fg-color);
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  line-height: 1.5;
}
main .sec05 .contact-btn {
  margin: 10vw auto 0;
  padding: 0.5rem 6%;
  display: block;
  width: 84%;
  max-width: 1000px;
  aspect-ratio: 1000 / 140;
  background: var(--color-theme);
  border-radius: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
main .sec05 .contact-btn .contact-icon {
  width: 12%;
}
main .sec05 .contact-btn .contact-info {
  position: relative;
  width: 84%;
  color: #fff;
  font-size: min(3.2vw, 36px);
  font-weight: 700;
  text-align: left;
}
main .sec05 .contact-btn .contact-info::after {
  position: absolute;
  content: "";
  top: calc(50% - min(2vw, 20px) * 0.5);
  left: 100%;
  width: min(2vw, 20px);
  height: min(2vw, 20px);
  border-right: 4px solid #fff;
  border-bottom: 4px solid #fff;
  border-radius: 2px;
  transform: rotate(-45deg);
}

@media (min-width: 800px) {
  header .fv-text {
    top: 5vw;
    left: calc(50% - (100% * 1223 / 1920) * 0.5);
    width: calc(100% * 1223 / 1920);
  }
  main .sec01 h2.sec01-title {
    font-size: clamp(24px, 2.8vw, 34px);
  }
  main .sec01 .sec01-flex {
    justify-content: space-between;
    gap: 0;
  }
  main .sec01 .sec01-flex .flex-item {
    width: 31%;
    aspect-ratio: 360 / 170;
    font-size: clamp(18px, 2vw, 24px);
  }
  main .sec01 .sec01-flex .flex-item.item1::after {
    top: 60%;
    right: -5%;
    width: 25%;
  }
  main .sec01 .sec01-flex .flex-item.item2::after {
    top: 60%;
    right: -5%;
    width: 25%;
  }
  main .sec01 .sec01-flex .flex-item.item3::after {
    top: 60%;
    right: -5%;
    width: 25%;
  }
  main .sec01-arrow .arrow2 {
    top: 32%;
    left: calc(50% - 5.5vw * 0.5);
    width: 5.5vw;
    height: 1.9vw;
  }
  main .sec01-arrow .arrow3 {
    top: 45%;
    left: calc(50% - 5.5vw * 0.5);
    width: 5.5vw;
    height: 1.9vw;
  }
  main .sec02 {
    padding: 0 0 12vw;
  }
  main .sec02 .text1 {
    font-size: clamp(16px, 1vw, 20px);
  }
  main .sec02 .text2 {
    font-size: clamp(24px, 2.8vw, 34px);
  }
  main .sec02 .text3 {
    font-size: clamp(18px, 1.3vw, 26px);
  }
  main .sec02 .cta-btn {
    top: 100%;
    left: 50%;
    width: 67%;
  }

  main .sec03 {
  }
  main .sec03 .merit-flex3 {
    margin: 2rem auto;
  }
  main .sec03 .merit-flex2 {
    margin: 2rem auto;
  }
  main .sec03 .merit-flex3 .merit-item {
    margin: 0;
    width: 32%;
  }
  main .sec03 .merit-flex2 .merit-item {
    margin: 0;
    width: 49%;
  }
  main .sec03 .merit-flex3 .merit-item::before,
  main .sec03 .merit-flex2 .merit-item::before {
    top: 6%;
    left: 0;
    color: #f4f4f4;
    font-size: min(10vw, 130px);
  }
  main .sec03 .merit-flex3 .merit-item .merit-head,
  main .sec03 .merit-flex2 .merit-item .merit-head {
    font-size: min(2.5vw, 30px);
    height: auto;
  }
  main .sec03 .merit-flex3 .merit-item .merit-head {
    aspect-ratio: 380 / 130;
  }
  main .sec03 .merit-flex2 .merit-item .merit-head {
    aspect-ratio: 580 / 130;
  }
  main .sec03 .merit-flex3 .merit-item .merit-desc,
  main .sec03 .merit-flex2 .merit-item .merit-desc {
    font-size: min(1.6vw, 20px);
    height: auto;
  }
  main .sec03 .merit-flex3 .merit-item .merit-desc {
    aspect-ratio: 380/ 120;
  }
  main .sec03 .merit-flex2 .merit-item .merit-desc {
    aspect-ratio: 580/ 120;
  }
  main .sec03 .merit-item a {
    display: inline;
    color: var(--color-theme);
    text-decoration: underline;
  }

  main .sec04 .study-box {
    margin: 6rem auto 0;
    padding: 2% 5%;
  }
  main .sec04 .study-box .study-info {
    padding: 2% 0 0 5%;
    font-size: min(2vw, 28px);
  }
  main .sec04 .study-box .study-memo {
    font-size: min(1vw, 16px);
  }
  main .sec04 .study-links {
    margin: 0 auto 4rem;
  }
  main .sec04 .study-links .study-look {
    font-size: min(1.6vw, 20px);
  }
  main .sec04 .study-links .study-link {
    font-size: min(1vw, 16px);
  }
  main .sec05 .contact-btn {
    margin: 80px auto 0;
  }
}

footer {
  padding: 4rem 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
footer .footer-logo-miruone {
  margin: 0 auto 1rem;
  width: 60%;
  max-width: 440px;
}
footer .footer-logo-company {
  width: 10em;
}
footer .footer-logo-riskma {
  width: 7em;
}
.footer-logos {
  width: 40%;
  min-width: 300px;
}
footer .footer-links {
  margin-top: 1rem;
  min-width: 200px;
  text-align: center;
  font-size: min(1vw, 16px);
}
footer .copyright {
  width: 90%;
  color: #666;
  font-size: min(1vw, 16px);
  text-align: center;
}
</style>
